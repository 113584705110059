const resource = {
  "breadcrumb.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "filter.category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorieën"])},
  "form.loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulier wordt geladen..."])},
  "layouts.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spuniti - Totaal leverancier voor horeca"])},
  "store.label.opening-hours.closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesloten"])},
  "store.label.opening-hours.show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon openingsuren"])},
  "store.label.route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Route"])},
  "store.title.contact-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactgegevens"])},
  "store.title.location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op de kaart"])},
  "store.title.opening-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Openingsuren"])}
}
export default resource