import nl from "@/locales/generated/nl-be";
import fr from "@/locales/generated/fr-be";

export default defineI18nConfig(() => ({
  legacy: false,
  locale: "nl-be",
  fallbackLocale: "nl-be",
  fallbackWarn: false,
  missingWarn: false,
  messages: {
    "nl-be": nl,
    "fr-be": fr,
  },
}));
