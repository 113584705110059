const resource = {
  "breadcrumb.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "filter.category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégories"])},
  "form.loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading form..."])},
  "layouts.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spuniti - Tous pour le horeca"])},
  "store.label.opening-hours.closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermé"])},
  "store.label.opening-hours.show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les heures d'ouverture"])},
  "store.label.route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Route"])},
  "store.title.contact-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de contact"])},
  "store.title.location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur la carte"])},
  "store.title.opening-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heures d 'ouverture"])}
}
export default resource